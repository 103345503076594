import Image from 'next/image';
import Link from '@components/ui/link';
import cn from 'classnames';
import { navSchema } from '@constants/nav-schema';

export default function Logo({ className, inverted = false, ...rest }: any) {
  return (
    <Link
      href={navSchema.logo.href}
      className={cn(
        'col-span-2 inline-flex justify-start items-center focus:outline-none',
        className
      )}
      {...rest}
    >
      <Image
        src={inverted ? navSchema.logo.light : navSchema.logo.dark}
        alt={navSchema.logo.alt}
        height={navSchema.logo.height}
        width={navSchema.logo.width}
        layout="fixed"
        loading="eager"
      />
    </Link>
  );
}
