import React, { ReactElement } from 'react';

interface Props {
  className?: string;
  children?: React.ReactNode;
  href?: string;
  profile: any;
}

export default function SocialIcon({
  className,
  children,
  profile,
  href,
}: Props): ReactElement {
  return (
    <a
      href={href}
      target="_blank"
      aria-label={`${profile.platform}`}
      rel="noreferrer"
      className={`flex justify-center border border-black items-center w-full md:w-10 h-10 text-heading hover:border-heading hover:text-telesoft transition duration-300 ${className}`}
    >
      {children}
    </a>
  );
}
