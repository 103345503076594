import React from 'react';
import NextLink, { LinkProps } from 'next/link';

export default function Link({
  href = '#!',
  children,
  ...props
}: {
  href: LinkProps['href'];
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <NextLink href={href}>
      <a {...props}>{children}</a>
    </NextLink>
  );
}
