import React, { useRef } from 'react';
import HeaderMenu from '@components/layout/header/header-menu';
import Logo from '@components/ui/logo';
import Button from '@components/ui/button';
import Container from '@components/ui/container';
import { navSchema } from '@constants/nav-schema';
import { addActiveScroll } from '@utils/add-active-scroll';
import { useWindowScroll } from '@utils/use-window-scroll';
import Link from 'next/link';
import Grid from '@components/grid';
import CoolHeaderMenuButton from './cool-header-menu-button';
import { VscChevronRight } from 'react-icons/vsc';

interface HeaderProps {
  variant?: 'fixed' | 'default';
}

type DivElementRef = React.MutableRefObject<HTMLDivElement>;
const { site_header } = navSchema;
function NewHeader({ variant = 'default' }: HeaderProps) {
  const siteHeaderRef = useRef() as DivElementRef;
  addActiveScroll(siteHeaderRef);

  const { y } = useWindowScroll();
  const isScrolling = y > 0 ? true : false;

  return (
    <React.Fragment>
      <header
        id={variant === 'fixed' ? 'siteHeaderAlt' : 'siteHeader'}
        ref={siteHeaderRef}
        className={`w-full z-50 ${
          variant === 'fixed' ? 'fixed' : 'sticky top-0'
        }`}
      >
        <div
          className={`${
            variant === 'fixed'
              ? 'innerStickyAlt text-white'
              : 'innerSticky bg-white text-black'
          } border-b border-transparent body-font py-2.5 xl:py-0 transition duration-200 ease-in-out`}
        >
          <Container className="h-full">
            <Grid className="place-items-stretch place-content-center h-full">
              <Logo
                inverted={
                  variant === 'fixed' ? (isScrolling ? false : true) : false
                }
              />
              <HeaderMenu data={site_header.menu} className="hidden xl:flex" />

              <CoolHeaderMenuButton
                inverted={
                  variant === 'fixed' ? (isScrolling ? false : true) : false
                }
              />
              <div className="col-span-4 hidden 2xl:flex justify-end items-center">
                <Link passHref href="/contact-us">
                  <Button
                    id="ctaNav"
                    invert={
                      variant === 'fixed' ? (isScrolling ? false : true) : false
                    }
                    variant={variant === 'fixed' ? 'flatLight' : 'flat'}
                    className={`flex-grow ${
                      variant === 'fixed'
                        ? isScrolling
                          ? 'border-black'
                          : 'border-white text-white hover:border-black'
                        : 'text-black'
                    }`}
                  >
                    Contact
                  </Button>
                </Link>
              </div>
            </Grid>
          </Container>
        </div>
      </header>
    </React.Fragment>
  );
}

export default NewHeader;
