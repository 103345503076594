import Container from '@components/ui/container';
import { motion } from 'framer-motion';
interface DividerProps {
  className?: string;
  withLine?: boolean;
  dashed?: boolean;
}

// TODO types

const Divider: React.FC<DividerProps> = ({
  className,
  withLine = false,
  dashed = false,
}) => {
  return (
    <Container>
      <motion.div
        initial={{ width: '10px' }}
        animate={{ width: 'auto' }}
        className={` ${
          withLine
            ? `border-t border-black my-6 lg:my-7 xl:my-8 ${
                dashed && 'border-dashed'
              }`
            : 'py-6 lg:py-7 xl:py-8'
        } ${className}`}
      />
    </Container>
  );
};

export default Divider;
