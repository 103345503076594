import AnimatedLineContent from '@components/animated-line-content';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

export default function ClusterHeader({
  title,
  className = 'mb-2 md:mb-4 lg:mb-6 xl:mb-8',
  children,
}: {
  title: string;
  border?: boolean;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <motion.div
      className={`font-terminal flex justify-between relative items-center ${className}`}
    >
      <AnimatedLineContent />
      <motion.h5 className="text-white text-sm tracking-wide font-light my-2">
        {title}
      </motion.h5>

      {children}
    </motion.div>
  );
}
