import { NextSeo } from 'next-seo';
import { Toaster } from 'react-hot-toast';
import LineFooter from './footer/line-footer';
import NewHeader from './header/new-header';
import { ReactNode } from 'react';
import PreviewMode from '@components/ui/preview-mode';

interface Props {
  children: ReactNode;
  variant?: 'default' | 'fixed';
  previewMode?: boolean;
}

const Template: React.FC<Props> = ({
  children,
  variant = 'default',
  previewMode = false,
}) => {

  return (
    <>
      {previewMode && <PreviewMode />}
      <div className="flex flex-col min-h-screen">
        <NextSeo
          title="Telesoft Technologies | Leading Cyber & Telco Provider"
          description="Continuous Innovation in Cyber & Telecoms Network Monitoring & Security. Scalable Network Monitoring, Data Retention And Analytics Platform For Digital Forensics. Threat Intelligence. NetworkAnalytics. Threat Hunting. Traffic Analysis. Security Monitoring."
          canonical={process.env.NEXT_PUBLIC_HOST_URL}
          openGraph={{
            url: process.env.NEXT_PUBLIC_HOST_URL,
            title: 'Telesoft Technologies | Leading Cyber & Telco Provider',
            description:
              'Continuous Innovation in Cyber & Telecoms Network Monitoring & Security. Scalable Network Monitoring, Data Retention And Analytics Platform For Digital Forensics. Threat Intelligence. NetworkAnalytics. Threat Hunting. Traffic Analysis. Security Monitoring.',
            images: [
              {
                url: '/assets/images/og-image-01.png',
                width: 800,
                height: 600,
                alt: 'Telesoft London',
              },
            ],
          }}
        />
        <NewHeader variant={variant ? variant : 'default'} />
        <main
          className="relative flex-grow"
          style={{
            minHeight: '-webkit-fill-available',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {children}
        </main>
        <LineFooter />
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              borderRadius: 0,
              fontSize: '14px',
              borderTop: '1px solid',
              borderColor: '#1ea1dc',
              background: '#000',
              color: 'white',
            },
            loading: {
              duration: 3000,
            },
          }}
        />
      </div>
    </>
  );
};

export default Template;
