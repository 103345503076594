export const navSchema = {
  name: 'Telesoft Technologies | Leading Cyber & Telco Provider',
  description:
    'Continuous Innovation in Cyber & Telecoms Network Monitoring & Security. Scalable Network Monitoring, Data Retention And Analytics Platform For Digital Forensics. Threat Intelligence. NetworkAnalytics. Threat Hunting. Traffic Analysis. Security Monitoring.',
  author: {
    name: 'Telesoft Technologies',
    websiteUrl: 'https://telesoft-technologies.com',
  },
  logo: {
    url: '/assets/images/logo.svg',
    light: '/assets/images/logo-light.svg',
    dark: '/assets/images/logo.svg',
    alt: 'Telesoft Technologies',
    href: '/',
    width: 180,
    height: 56,
  },
  defaultLanguage: 'en',
  site_header: {
    menu: [
      {
        id: 1,
        path: '/products',
        label: 'Products',
        description: 'View all Products',
        columns: [
          {
            id: 1,
            columnItems: [
              {
                id: 1,
                path: '/products',
                label: 'Our Products',
                description: 'PRODUCTS',
                columnItemItems: [
                  {
                    id: 1,
                    path: '/products/tdac',
                    label: 'TDAC Suite',
                    description: 'All-in-one Network Visibility',
                  },
                  {
                    id: 2,
                    path: '/products/flowprobe',
                    label: 'FlowProbe',
                    description: 'Unsampled TBps Data Ingest',
                  },
                  {
                    id: 3,
                    path: '/products/cerne',
                    label: 'CERNE',
                    description: 'Network Monitoring at Scale',
                  },
                  {
                    id: 4,
                    path: '/products/mobile-signalling-probe',
                    label: 'Mobile Signalling Probe',
                    description: 'Mobile Data Compliance',
                  },
                  {
                    id: 5,
                    path: '/products/data-lake',
                    label: 'Data Lake',
                    description: 'Petabyte Data Retention',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 2,
        path: '/solutions',
        label: 'Solutions',
        description: 'View all Solutions',
        columns: [
          {
            id: 1,
            columnItems: [
              {
                id: 1,
                path: '/solutions#by-industry',
                label: 'By Industry',
                description: 'VIEW ALL',
                columnItemItems: [
                  {
                    id: 1,
                    path: '/solutions/telco',
                    label: 'Telco',
                  },
                  {
                    id: 2,
                    path: '/solutions/isp',
                    label: 'ISP',
                  },
                  {
                    id: 3,
                    path: '/solutions/enterprise',
                    label: 'Enterprise',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        path: '/about',
        label: 'Company',
        description: 'Who We Are',
        subMenu: [
          {
            id: 1,
            label: 'About Telesoft',
            path: '/about',
          },
          {
            id: 2,
            label: 'Corporate News',
            path: '/content/news',
          },
          {
            id: 3,
            label: 'Support Plans',
            path: '/support',
          },
          {
            id: 4,
            label: 'Careers',
            path: '/careers',
          },
          {
            id: 5,
            label: 'Regional Offices',
            path: '/regional-offices',
          },
          {
            id: 6,
            label: 'Contact',
            path: '/contact-us',
          },
        ],
      },
      {
        id: 4,
        path: '/blog',
        label: 'Resources',
        description: 'Go to Resources',
        subMenu: [
          {
            id: 1,
            label: 'Blog: Nucleus',
            path: '/blog',
          },
          {
            id: 2,
            label: 'Government Services',
            path: '/content/government-services',
          },
          {
            id: 3,
            label: 'Cyber Security',
            path: '/content/cyber-security',
          },
          {
            id: 4,
            label: 'Reports',
            path: '/content/reports',
          },
          {
            id: 5,
            label: 'Whitepapers',
            path: '/content/whitepapers',
          },
          {
            id: 6,
            label: 'Datasheets',
            path: '/content/datasheets',
          },
        ],
      },
    ],
  },
};
