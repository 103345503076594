import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  id?: string;
  style?: any;
}

export default function Grid({
  children,
  className,
  id,
  style,
}: Props): ReactElement {
  // grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5 md:gap-9 xl:gap-5  pb-9 md:pb-14 lg:pb-16 2xl:pb-20 3xl:pb-24 lg:mb-0.5 2xl:mb-0 3xl:-mb-1
  return (
    <div
      id={id}
      className={`grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 grid-rows-auto gap-8 ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
