import axios from 'axios';
import { Article } from '@constants/types';
import { API_ENDPOINTS } from '@constants/api-endpoints';
import { useQuery } from 'react-query';
import { API_URL } from '@lib/constants';

export const fetchArticlesByCategory = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;

  const response = await axios({
    method: 'GET',
    url: `${API_URL}/posts?where[category.slug][equals]=${_params.category}`,
  });

  const articles = await response.data.docs;

  return articles;
};
export const useArticlesByCategoryQuery = (options?: any) => {
  return useQuery<[articles: Article[]], Error>(
    [API_ENDPOINTS.ARTICLES_BY_CATEGORY, options],
    fetchArticlesByCategory,
    {
      // enabled: !!options.category
    }
  );
};
