import React from 'react';
import Grid from '@components/grid';
import Container from '@components/ui/container';
import Copyright from './copyright';

import FooterLinkItem from './footer-link-item';
import { useInView } from 'react-intersection-observer';
import AnimatedLineContent from '@components/animated-line-content';
import mainFooter from '@globals/footer.preval';
import Image from 'next/image';

interface IColumns {
  variant?: 'light' | 'dark';
  columns?: {
    id: number;
    columnLabel: string;
    lists: any;
  }[];
}

const LineFooter: React.FC<IColumns> = ({ variant = 'dark' }) => {
  return (
    <footer className="mt-9 md:mt-11 lg:mt-16 3xl:mt-20 pt-2.5 lg:pt-0 2xl:pt-2">
      <Container>
        <Grid className="gap-y-12">
          {mainFooter?.menus.map((menu: any, i: number) => (
            <ColumnLinkItem
              key={`footer-column--key${menu.id}`}
              index={i}
              data={menu}
              variant={variant}
            />
          ))}
          <Image
            loading="lazy"
            src={`/assets/images/lrqa-1.png`}
            alt=""
            className="object-contain object-bottom"
            width={100}
            height={70}
          />
          <Image
            loading="lazy"
            src={`/assets/images/lrqa-2.png`}
            alt=""
            className="object-contain object-bottom"
            width={100}
            height={70}
          />
          <Image
            loading="lazy"
            src={`/assets/images/cyber-essentials.png`}
            alt=""
            className="object-contain object-left"
            width={70}
            height={56}
          />
        </Grid>
      </Container>
      <Copyright />
    </footer>
  );
};

export default LineFooter;

export function ColumnLinkItem({ data, variant = 'dark', index }: any) {
  const { label, menuLinks } = data;

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  return (
    <div ref={ref} className="col-span-4 relative">
      {inView && <AnimatedLineContent index={index} variant="dark" />}
      <div className={`grid grid-cols-2 `}>
        <div className="col-span-1 pt-3">
          <h5 className="font-terminal tracking-wide text-sm">{label}</h5>
        </div>
        <div className="col-span-1">
          <ul
            className={`style list-none text-[15px] ${
              variant === 'dark' ? 'text-black' : 'text-white'
            }`}
          >
            {menuLinks.map((linkItem: any, i: number) => {
              const { link } = linkItem;
              return (
                <FooterLinkItem
                  key={`column-list--key${linkItem.id}`}
                  label={link.label}
                  index={i}
                  link={link}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
