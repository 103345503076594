const API_URL = `${process.env.NEXT_PUBLIC_API_URL}`;

export const API_ENDPOINTS = {
  CATEGORIES_ORDERED: API_URL + '/categories?sort=options.order',
  CATEGORIES: API_URL + '/categories',
  CATEGORY: API_URL + '/categories',
  FEATURED_ARTICLES: API_URL + '/graphql',
  ARTICLES: API_URL + '/posts',
  ARTICLES_BY_CATEGORY: '/api/posts/category',
  FEATURED_PRODUCT: API_URL + '/products',
  PARTNERS: API_URL + '/partners',
  PRODUCTS: API_URL + '/products',
  PRODUCT: API_URL + '/product',
  SEARCH: API_URL + '/search',
};
