import React from 'react';
import { motion } from 'framer-motion';
import { BsArrowUpRight } from 'react-icons/bs';
import { VscChevronRight } from 'react-icons/vsc';
import CMSLink from '@components/CMSLink';
import Link from 'next/link';

export default function ViewAllLink({
  href = null,
  link,
  label,
  onClick,
  borders = true,
}: {
  link?: any;
  href?: string | null;
  label?: any;
  onClick?: any;
  borders?: boolean;
}) {
  if (href !== null) {
    return (
      <Link passHref href={href ? href : '#!'}>
        <motion.a
          initial={{ opacity: 0, y: borders ? -20 : 0 }}
          animate={{ opacity: 1, y: 0 }}
          onClick={onClick}
          className={`font-terminal ${
            borders
              ? ' border-r px-4 border-white'
              : 'border-b border-transparent'
          } cursor-pointer py-0.5 flex justify-end tracking-wide transition-all duration-300 items-center border-opacity-70 hover:border-opacity-100 text-opacity-70 hover:text-opacity-100 text-white text-xs uppercase  hover:border-telesoft`}
        >
          <VscChevronRight className="mr-1" />
          <span>{label}</span> {!borders && <BsArrowUpRight className="ml-1" />}
        </motion.a>
      </Link>
    );
  } else
    return (
      <CMSLink {...link}>
        <motion.div
          initial={{ opacity: 0, y: borders ? -20 : 0 }}
          animate={{ opacity: 1, y: 0 }}
          onClick={onClick}
          className={`font-terminal ${
            borders
              ? ' border-r px-4 border-white'
              : 'border-b border-transparent'
          } cursor-pointer py-0.5 flex justify-end tracking-wide transition-all duration-300 items-center border-opacity-70 hover:border-opacity-100 text-opacity-70 hover:text-opacity-100 text-white text-xs uppercase  hover:border-telesoft`}
        >
          <VscChevronRight className="mr-1" />
          <span>{label}</span> {!borders && <BsArrowUpRight className="ml-1" />}
        </motion.div>
      </CMSLink>
    );
}
