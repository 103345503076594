import React, { ReactElement } from 'react';
import { VscArrowRight } from 'react-icons/vsc';
import CMSLink from '@components/CMSLink';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface Props {
  label: string;
  link: any;
  button?: boolean;
  variant?: 'light' | 'dark';
  index?: number;
  onClick?: () => void | undefined;
}

function FooterLinkItem({
  label,
  link,
  onClick,
  index = 0,
  button = false,
  variant = 'dark',
}: Props): ReactElement {
  const { ref, inView } = useInView({
    threshold: 0.4,
  });
  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ delay: 0.2 * index }}
      ref={ref}
      className={`group overflow-hidden mt-[-1px] relative border-b flex justify-between items-center ${
        variant === 'dark' ? 'border-black' : 'border-white text-white'
      } hover:opacity-50 transition-opacity duration-300`}
    >
      {!button && (
        <CMSLink className="w-full" {...link}>
          <button className={'w-full text-left py-3'}>{label}</button>
        </CMSLink>
      )}

      {button && (
        <button
          type="button"
          onClick={onClick}
          className="w-full text-left py-3"
        >
          {label}
        </button>
      )}

      <VscArrowRight className="opacity-0 mr-4 group-hover:mr-0 text-lg group-hover:opacity-100 transition-all duration-300" />
    </motion.li>
  );
}

export default FooterLinkItem;
