import axios from 'axios';
import { Article } from '@constants/types';
import { API_ENDPOINTS } from '@constants/api-endpoints';
import { useQuery } from 'react-query';

export const fetchFeaturedPosts = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;

  const response = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API_URL}/posts?where[featured][equals]=true`,
  });

  const posts = await response.data.docs;

  return posts;
};
export const useFeaturedPostsQuery = (options?: any) => {
  return useQuery<[articles: Article[]], Error>(
    [API_ENDPOINTS.ARTICLES_BY_CATEGORY, options],
    fetchFeaturedPosts
  );
};
