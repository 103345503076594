import Text from '@components/ui/text';
import { Tab } from '@headlessui/react';
import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';
import { VscArrowRight } from 'react-icons/vsc';
import ClusterHeader from './cluster-header';
import ViewAllLink from './view-all-link';
import CMSLink from '@components/CMSLink';
import FadeInUp from '@utils/motion/fade-in-up';

interface Props {
  groupMenus: any;
  onClick: any;
}

function HeaderMenuPanelLinks({ groupMenus, onClick }: Props): ReactElement {
  return (
    <Tab.Panel
      as={motion.div}
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex flex-col justify-start items-start"
    >
      {groupMenus &&
        Array.isArray(groupMenus) &&
        groupMenus.map((menu: any, idx: number) => {
          return (
            <motion.div
              initial={{ opacity: 0, x: 28 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.1 * idx, ease: 'easeInOut' }}
              key={`menu--panel-key--${idx}`}
              className="w-full"
            >
              <ClusterHeader title={`0${idx + 2}/ ${menu.label}`} border={true}>
                {/* @ts-ignore */}
                <FadeInUp y={-10} delay={1}>
                  <ViewAllLink
                    link={menu.link}
                    onClick={onClick}
                    borders={false}
                    label={menu.link.label}
                  />
                </FadeInUp>
              </ClusterHeader>
              <ul className="mb-8">
                {menu.links.map(({ link }: any, i: number) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, x: 28 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.1 * i, ease: 'easeInOut' }}
                      key={`column--items-key--${i}`}
                    >
                      <li
                        key={`overlay-main--item-column-${idx}`}
                        className="w-full"
                      >
                        <CMSLink {...link}>
                          <div
                            onClick={onClick}
                            className="group flex justify-between items-center py-4 border-b border-transparent hover:border-telesoft"
                          >
                            <Text
                              className={`font-medium text-white text-opacity-70 group-hover:text-opacity-100 transition duration-300`}
                              variant="headingLight"
                            >
                              {link.label}
                              <span className="text-xs relative bottom-1 tracking-widest font-light text-white text-opacity-50 font-terminal">
                                {' '}
                                0{i + 1}
                              </span>
                            </Text>
                            <VscArrowRight className="text-white text-xl opacity-0 mr-5 group-hover:opacity-100 transition-all duration-300 group-hover:mr-0" />
                          </div>
                        </CMSLink>
                      </li>
                    </motion.div>
                  );
                })}
              </ul>
            </motion.div>
          );
        })}
    </Tab.Panel>
  );
}

export default HeaderMenuPanelLinks;
