import React, { ReactElement } from 'react';
import { Tab } from '@headlessui/react';
import { motion } from 'framer-motion';
import Text from './ui/text';
import ViewAllLink from './common/modal/view-all-link';

interface Props {
  option: string;
  index: number;
  link?: string;
  onClick?: any;
  onClickTab?: any;
  label?: string;
  invert?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

function LogoTabLink({
  option,
  index,
  link,
  onClick,
  invert = false,
  onClickTab,
}: Props): ReactElement {
  return (
    <Tab
      className={({ selected }) =>
        classNames(
          'group cursor-pointer py-8 border-b flex relative justify-between items-center w-full',
          invert ? 'border-black' : 'border-white',
          'focus:outline-none outline-none',
          selected
            ? 'pl-8  transition-all duration-300'
            : ' border-opacity-70 hover:border-telesoft'
        )
      }
      as="li"
    >
      {({ selected }) => (
        <>
          {selected && (
            <>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                className="absolute left-0 top-[32px] md:top-[33px] xl:top-[35px] flex"
              >
                <div className="w-[2px] bg-telesoft h-5 mr-0.5"></div>
                <div className="w-[2px] bg-telesoft h-5 mr-0.5"></div>
                <div className="w-[2px] bg-telesoft h-5 mr-0.5"></div>
                <div className="w-[2px] bg-telesoft h-5 mr-0.5"></div>
                <div className="w-[2px] bg-telesoft h-5 mr-0.5"></div>
              </motion.div>
            </>
          )}
          <div onClick={onClickTab}>
            <Text
              className={`font-medium ${
                selected
                  ? invert
                    ? 'text-black'
                    : 'text-white'
                  : 'text-opacity-70 group-hover:text-opacity-100 transition-all duration-300'
              }`}
              variant={invert ? 'heading' : 'headingLight'}
            >
              {option}
              <span
                className={classNames(
                  'text-xs relative bottom-1 tracking-widest font-light  text-opacity-50 font-terminal',
                  invert ? 'text-black' : 'text-white'
                )}
              >
                {' '}
                0{index + 1}
              </span>
            </Text>
          </div>

          {selected && link && (
            <ViewAllLink
              link={link}
              onClick={onClick}
              //@ts-ignore
              label={link.label || 'EXPLORE'}
            />
          )}
        </>
      )}
    </Tab>
  );
}

export default LogoTabLink;
