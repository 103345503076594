import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ReactNode } from 'react';

export default function FadeInUp({ children, y, delay, duration }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{
        duration: duration ? duration : 0.4,
        delay: delay ? delay : 0,
      }}
      variants={{
        visible: { y: 0, opacity: 1 },
        hidden: { y: y ? y : 60, opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}
