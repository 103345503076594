import React, { ReactElement } from 'react';
import HeaderModal from '@components/common/modal/header-modal';

interface Props {
  inverted?: boolean;
}

function CoolHeaderMenuButton({ inverted }: Props): ReactElement {
  const [headerIsOpen, setHeaderIsOpen] = React.useState(false);

  function closeModal() {
    setHeaderIsOpen(false);
  }

  return (
    <>
      <div className="col-span-1 col-start-4 cold-end-5 md:col-start-auto md:order-last md:col-end-9 lg:order-none lg:col-end-8 xl:col-end-9 flex h-full justify-end items-center">
        <button
          onClick={() => setHeaderIsOpen((prev) => !prev)}
          type="button"
          aria-label="Main Menu"
          className={`group inline-flex items-center cursor-pointer font-body text-center border justify-center focus-visible:outline-none focus:outline-none bg-transparent ${
            inverted
              ? 'border-white text-white hover:border-black'
              : 'border-black text-black'
          } px-3 py-3.5 md:py-3 md:w-full lg:px-3 lg:py-3.5 hover:bg-telesoft hover:border-telesoft hover:shadow-network`}
        >
          <div className={`${headerIsOpen ? 'active' : 'not-active'}  `}>
            <span
              className={`btnLine  ${
                inverted ? 'bg-white group-hover:bg-black' : 'bg-black'
              }`}
            ></span>
            <span
              className={`btnLine  ${
                inverted ? 'bg-white group-hover:bg-black' : 'bg-black'
              }`}
            ></span>
            <span
              className={`btnLine  ${
                inverted ? 'bg-white group-hover:bg-black' : 'bg-black'
              }`}
            ></span>
          </div>
        </button>
      </div>
      <HeaderModal showModal={headerIsOpen} closeModal={closeModal} />
    </>
  );
}

export default CoolHeaderMenuButton;
