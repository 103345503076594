import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';

interface Props {
  variant?: 'light' | 'dark';
  index?: number;
}

const animateLineToRight = {
  initial: {
    width: 0,
  },
  animate: {
    width: 'auto',
    transition: {
      duration: 1,
      ease: 'easeInOut',
      when: 'afterChildren',
    },
  },
};

function AnimatedLineContent({
  variant = 'light',
  index = 0,
}: Props): ReactElement {
  return (
    <motion.div
      variants={animateLineToRight}
      initial="initial"
      animate="animate"
      transition={{ delay: 0.6 * index }}
      className={`w-full h-[1px] ${
        variant === 'light' ? 'bg-white' : 'bg-black'
      } absolute top-0 left-0 right-0`}
    />
  );
}

export default AnimatedLineContent;
