import classNames from 'classnames';
import { BsArrowUpRight, BsChevronDown } from 'react-icons/bs';
import mainHeader from '@globals/header.preval';
import CMSLink from '@components/CMSLink';
import { Menu } from '@headlessui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { IoMdReturnRight } from 'react-icons/io';
import Text from '@components/ui/text';
import Grid from '@components/grid';
import Container from '@components/ui/container';
import Image from 'next/image';
import ResourceItem from '@components/resource-item';

interface MenuProps {
  data: any;
  className?: string;
}

const HeaderMenu: React.FC<MenuProps> = ({ className }) => {
  const { menus } = mainHeader;
  const filteredMenus = menus.filter(
    (m: any) =>
      m.label === 'Products' ||
      m.label === 'Solutions' ||
      m.label === 'Services'
  );

  return (
    <nav
      className={classNames(
        `col-span-5 flex w-full justify-end ms-4 items-end`,
        className
      )}
    >
      {filteredMenus?.map((menu: any, i: number) => {
        const menuIdx = i;
        return (
          <Menu key={`sub-menu-map-${i}`}>
            {({ open }) => (
              <>
                <Menu.Button
                  id={`dropdown--btn--${menuIdx}`}
                  onClick={() => (open = false)}
                  as={'button'}
                  className={`group inline-flex py-7 items-center text-sm xl:text-base px-3 xl:px-4 font-medium capitalize relative`}
                >
                  <span> {menu.label}</span>
                  <span className="opacity-30 text-xs mt-1 xl:mt-0.5 w-4 flex justify-end">
                    <BsChevronDown
                      className={`${
                        open && '-rotate-180'
                      } transition duration-300 ease-in-out transform`}
                    />
                  </span>
                </Menu.Button>
                {open && (
                  <motion.div
                    initial={{ opacity: 0, height: 0, translateX: 20 }}
                    animate={{ opacity: 1, height: 'auto', translateX: 0 }}
                    exit={{ opacity: 0, height: 0, translateX: 20 }}
                    transition={{ ease: 'easeInOut', duration: 0.3 }}
                    className="absolute left-0 right-0 top-[81px] mx-auto w-full focus:outline-none"
                  >
                    <Menu.Items static className={`w-full focus:outline-none`}>
                      <Container>
                        <Grid className="gap-y-0">
                          {menu.menuGroups?.map((menuGroup: any, i: number) => {
                            const groupLink = menuGroup.link;
                            const { links } = menuGroup;
                            return (
                              <React.Fragment
                                key={`menu--group--map--${menuGroup.id}`}
                              >
                                <motion.ul
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  transition={{ when: 'afterChildren' }}
                                  className={`col-span-8 backdrop-blur-md bg-black bg-opacity-95 pb-7 2xl:pb-8 pt-6 2xl:pt-7 ${
                                    i === 1 && 'pt-0 2xl:pt-0'
                                  } w-full shadow-xl `}
                                >
                                  <li className="mb-1.5">
                                    <div className="block text-sm py-1.5 cursor-default uppercase text-white px-5 xl:px-8 2xl:px-10">
                                      {menuGroup.label}
                                    </div>
                                  </li>
                                  {links.map((item: any, i: number) => {
                                    const { link } = item;
                                    return (
                                      <Menu.Item
                                        as={CMSLink}
                                        {...link}
                                        key={`menu--item-map--${i}`}
                                      >
                                        <motion.li
                                          initial={{ opacity: 0, y: 28 }}
                                          animate={{ opacity: 1, y: 0 }}
                                          transition={{
                                            delay: 0.1 * i,
                                            ease: 'easeInOut',
                                          }}
                                          className={'group relative'}
                                        >
                                          <div className="text-white w-full font-medium flex flex-col py-1.5 px-5 xl:px-8 2xl:px-10 border-b border-transparent hover:border-telesoft hover:bg-white hover:bg-opacity-10 transition-all duration-300">
                                            <div className="flex justify-start links-center">
                                              <IoMdReturnRight className="mr-3 mt-1" />
                                              {link.label}
                                            </div>

                                            {item.hasDescription && (
                                              <span className="ml-[29px] mt-1 font-light text-xs">
                                                {item.description}
                                              </span>
                                            )}
                                          </div>
                                        </motion.li>
                                      </Menu.Item>
                                    );
                                  })}
                                </motion.ul>
                                {i === 0 && (
                                  <div className="col-span-4 row-span-2 bg-telesoft bg-opacity-90 backdrop-blur-md relative p-8 w-full shadow-xl">
                                    {menu?.showPost && (
                                      <Menu.Item {...groupLink}>
                                        <div className="relative border-t border-b border-black hover:border-black text-black z-10 w-full h-full flex justify-center items-center hover:bg-black hover:text-white transition duration-300">
                                          <ResourceItem resource={menu.post} />
                                        </div>
                                      </Menu.Item>
                                    )}

                                    {!menu?.showPost && (
                                      <Menu.Item as={CMSLink} {...groupLink}>
                                        <div className="relative border-t border-b border-black hover:border-black text-black z-10 w-full h-full flex justify-center items-center hover:bg-black hover:text-white transition duration-300">
                                          <Image
                                            src={
                                              i === 0
                                                ? '/assets/images/telesoft-abstract-42.jpeg'
                                                : '/assets/images/telesoft-abstract-38.jpeg'
                                            }
                                            className="z-0 opacity-60"
                                            layout="fill"
                                          />
                                          <div className="flex z-10 text-white justify-center items-center border-b border-transparent group-hover:border-telesoft transition duration-300">
                                            <BsArrowUpRight className="text-sm" />
                                            <Text
                                              variant="body"
                                              className="font-terminal ml-2 uppercase"
                                            >
                                              {groupLink.label}
                                            </Text>
                                          </div>
                                        </div>
                                      </Menu.Item>
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </Container>
                    </Menu.Items>
                  </motion.div>
                )}
              </>
            )}
          </Menu>
        );
      })}
    </nav>
  );
};

export default HeaderMenu;
