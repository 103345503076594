import React, { ReactElement, Fragment } from 'react';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import Container from '@components/ui/container';
import Grid from '@components/grid';
import LogoTabLink from '@components/logo-tab-link';
import ClusterHeader from './cluster-header';
import HeaderMenuPanelLinks from './header-menu-panel-links';
import ContentSpotlightItem from './content-spotlight-item';
import { useFeaturedPostsQuery } from '@queries/articles/get-all-featured-posts';
import { useArticlesByCategoryQuery } from '@queries/articles/get-articles-by-category';
import { ASSET_URL } from '@lib/constants';
import mainMenu from '@globals/header.preval';
import ViewAllLink from './view-all-link';
import FadeInUp from '@utils/motion/fade-in-up';

interface Props {
  showModal: any;
  closeModal: any;
  socialProfiles?: any;
}

export const menuListVariants = {
  initial: {
    opacity: 0,
    y: 28,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      ease: 'easeOut',
    },
  },
};

export const contentListVariant = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.4,
      ease: 'easeInOut',
    },
  },
};

export default function HeaderModal({
  showModal,
  closeModal,
}: Props): ReactElement {
  const focusElement = React.useRef(null);
  const { data: posts } = useFeaturedPostsQuery();
  const { data: newsPosts } = useArticlesByCategoryQuery({
    category: 'company-news',
  });

  let HOST_URL = '';
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      HOST_URL = window.location.host;
    }
  }, []);

  return (
    <Transition show={showModal} as={Fragment}>
      <Dialog
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        open={showModal}
        initialFocus={focusElement}
        className="z-30 fixed overflow-y-auto inset-0"
        onClose={() => {}}
      >
        <Dialog.Overlay className={`fixed inset-0 bg-black`} />

        <span className="inline-block h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          ref={focusElement}
          className="mx-auto relative inline-block w-full"
        >
          <Container className="mt-[94px] lg:mt-[122px] xl:mt-[124px]">
            <motion.div
              variants={menuListVariants}
              animate="animate"
              initial="initial"
            >
              <Grid className="">
                <Tab.Group vertical>
                  <Tab.List
                    className="col-span-4 lg:col-span-6 xl:col-span-4"
                    variants={menuListVariants}
                    as={motion.ul}
                  >
                    <ClusterHeader title="01/ NAVIGATION" border={true} />

                    {mainMenu.menus.map((header: any, idx: number) => {
                      const { link } = header.menuGroups[0];
                      return (
                        <LogoTabLink
                          key={`header-menu-link-${header.id}`}
                          option={header.label}
                          index={idx}
                          link={link}
                          onClick={closeModal}
                          label={header.description}
                        />
                      );
                    })}
                  </Tab.List>
                  <Tab.Panels
                    className="z-10 col-span-4 lg:col-span-6 xl:col-span-4"
                    as={motion.div}
                    variants={menuListVariants}
                  >
                    {mainMenu.menus.map((header: any) => {
                      return (
                        <HeaderMenuPanelLinks
                          key={`header-panel-link-${header.id}`}
                          onClick={closeModal}
                          groupMenus={header.menuGroups}
                        />
                      );
                    })}
                  </Tab.Panels>
                </Tab.Group>
                <motion.div
                  variants={menuListVariants}
                  className="col-span-4 md:col-span-full xl:col-span-4"
                >
                  <ClusterHeader title="LATEST: NEWS" border={true}>
                    {/* @ts-ignore */}
                    <FadeInUp y={-10} delay={1}>
                      <ViewAllLink
                        onClick={closeModal}
                        borders={false}
                        label={'COMPANY NEWS'}
                      />
                    </FadeInUp>
                  </ClusterHeader>

                  <motion.div
                    variants={contentListVariant}
                    initial="initial"
                    animate="animate"
                    className="grid grid-cols-2 gap-8"
                  >
                    {newsPosts &&
                      newsPosts?.slice(0, 2).map((post: any) => {
                        const formattedDate = format(
                          new Date(post.updatedAt),
                          'do MMM, yyyy'
                        );
                        return (
                          <ContentSpotlightItem
                            key={`content--post--key-${post.id}`}
                            title={post.title}
                            onClick={closeModal}
                            imageUrl={
                              post.image
                                ? ASSET_URL + '/' + post.image.filename
                                : '/assets/images/telesoft-sales-2.jpeg'
                            }
                            date={formattedDate}
                            href={`${HOST_URL}/blog/${post.slug}`}
                          />
                        );
                      })}

                    <ClusterHeader
                      title="FEATURED: NUCLEUS"
                      className="col-span-full mt-8"
                    >
                      {/* @ts-ignore */}
                      <FadeInUp y={-10} delay={1}>
                        <ViewAllLink
                          href={'/blog'}
                          onClick={closeModal}
                          borders={false}
                          label={'NUCLEUS BLOG'}
                        />
                      </FadeInUp>
                    </ClusterHeader>

                    {posts &&
                      posts?.slice(0, 2).map((post: any) => {
                        const formattedDate = format(
                          new Date(post.updatedAt),
                          'do MMM, yyyy'
                        );
                        return (
                          <ContentSpotlightItem
                            key={`content--post--key-${post.id}`}
                            title={post.title}
                            onClick={closeModal}
                            imageUrl={
                              post.image
                                ? ASSET_URL + '/' + post.image.filename
                                : '/assets/images/telesoft-sales-2.jpeg'
                            }
                            date={formattedDate}
                            href={`${HOST_URL}/blog/${post.slug}`}
                          />
                        );
                      })}
                  </motion.div>
                </motion.div>
              </Grid>
            </motion.div>
          </Container>
        </div>
      </Dialog>
    </Transition>
  );
}
