import Button from './button';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

interface IProps {}

export default function PreviewMode({}: IProps) {
  async function onClickExitPreview() {
    toast.loading('Exiting preview mode...', { id: 'EXIT' });
    await fetch('/api/exit-preview');
    toast.success('Done. Refreshing...', { id: 'EXIT' });
    window.location.href = window.location.href;
  }

  async function onClickRebuildMenus() {
    toast.loading('Menus rebuilding...', { id: 'PREVIEW' });
    // although public, only authenticated CMS admins running "Preview Mode" will render this component client-side.
    const response = await fetch(
      process.env.NEXT_PUBLIC_VERCEL_REBUILD_WEBHOOK_URL!
    );
    const vercelResp = await response.json();
    if (vercelResp.job.state === 'PENDING') {
      toast.success('Fresh build requested.', { id: 'PREVIEW' });
    } else {
      toast.error('Rebuild request failed.', { id: 'PREVIEW' });
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1 }}
      className="z-50 fixed space-y-4 bottom-8 left-8 p-8 bg-black flex flex-col bg-opacity-70 backdrop-blur-md justify-center"
    >
      <h5 className="font-terminal text-white text-sm text-opacity-70">
        PREVIEW MODE: <span className="animate-pulse text-green-500">ON</span>
      </h5>
      <Button
        onClick={onClickRebuildMenus}
        variant="cta"
        invert
        className="text-white"
      >
        Rebuild Menus
      </Button>
      <Button
        onClick={onClickExitPreview}
        variant="flatLight"
        invert
        className="text-white"
      >
        Exit Preview Mode
      </Button>
    </motion.div>
  );
}
